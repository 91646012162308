import React, { useState } from "react"
import Layout from "../templates/Layout"
import Seo from "../components/seo"
import styled, { keyframes } from "styled-components"
import textBottomGrey from "../assets/text-bottom-grey.svg"
import textBottomGreyMobile from "../assets/text-bottom-grey-mobile.svg"
import Paragraph from "../components/typography/Paragraph"
import HeadingH2 from "../components/typography/HeadingH2"
import signatureSrc from "../assets/signature.png"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import ReactVivus from "react-vivus"
import Rotate from "react-reveal/Rotate"
import Swing from "react-reveal/Swing"
import Fade from "react-reveal/Fade"
import Menu from "../components/menu/Menu"
import Pattern from "../assets/Vector.svg"
import "react-responsive-carousel/lib/styles/carousel.min.css" // requires a loader
import { Carousel } from "react-responsive-carousel"
import photoElement from "../assets/photo-element.svg"
import { useSelector, useDispatch } from "react-redux"
import {
  setActivePatternMenu,
  setActiveLanguage,
  setActivePage,
} from "../store/menuSlice"
import vividHover from "../assets/vivid-link-hover.svg"
import getHtmlFromRedactorField from "../utils/getHtmlFromRedactorField"

const StyledBackgroundWrapper = styled.div`
  min-height: -webkit-fill-available;
  width: 100vw;
  height: 100vh;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  @media (max-width: 1023px) {
    height: auto;
  }
  @media (max-width: 810px) {
    height: 100vh;
  }
  @media (max-width: 500px) {
    height: auto;
    align-items: flex-start;
    overflow-x: hidden;
    overflow-y: auto;
    /* position: relative; */
  }
`

const StyledContentWrapper = styled.div`
  width: 100%;
  height: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1024px) {
    flex-direction: column;
    padding: 0 10%;
    height: 100%;
    align-items: center;
    justify-content: flex-end;
  }
  @media (max-width: 500px) {
    height: auto;
    justify-content: flex-start;
    margin-top: 100px;
    padding: 0;
  }
`

const StyledPhotoWrapper = styled.div`
  width: 45%;
  height: auto;
  padding-right: 50px;
  @media (max-width: 1443px) {
    padding-right: 0px;
  }
  @media (max-width: 1024px) {
    width: 70%;
    padding-right: 0px;
  }
  @media (max-width: 450px) {
    width: 100%;
  }
`

const visibility = keyframes`
  from {
    
opacity:0;
  }

  to {
    
opacity:1
  }
  
`

const StyledCopyWrapper = styled.div`
  pointer-events: none;
  width: 55%;
  height: 100%;
  text-align: right;
  padding-right: 150px;
  padding-top: 7vh;
  position: relative;
  z-index: 2;
  @media (max-width: 2000px) {
    padding-top: 0vh;
    bottom: 4%;
  }
  @media (max-width: 1450px) {
    padding-top: 4vh;
    bottom: 0%;
  }
  @media (max-width: 1300px) {
    padding-top: 0vh;
  }
  @media (max-width: 1024px) {
    width: 100%;
    padding-right: 0px;
    padding-top: 12vh;
  }
  @media (max-width: 1023px) {
    padding-bottom: 5vh;
  }
  @media (max-width: 810px) {
    padding-top: 16vh;
  }
  @media (max-width: 500px) {
    padding-bottom: 0vh;
    padding: 0 10%;
    margin-bottom: 120px;
    bottom: 0%;
  }
`

const StyledTextBottom = styled.img`
  pointer-events: none;
  width: 100%;
  height: auto;
  position: absolute;
  bottom: 0px;
  left: 0;
  margin: 0;
  right: 0;
  display: block;
  @media (max-width: 500px) {
    display: none;
  }
`

const StyledTextBottomMobile = styled.img`
  pointer-events: none;
  width: 100%;
  height: auto;
  position: relative;
  bottom: 0px;
  left: 0;
  margin: 0;
  right: 0;
  display: none;
  @media (max-width: 500px) {
    display: block;
  }
`
const rotate = keyframes`
  from {
    transform: rotate(-90deg);
opacity:0;
  }

  to {
    transform: rotate(0deg);
opacity:1
  }
  
`

const StyledRectangle = styled.div`
  width: 500px;
  height: 500px;
  position: absolute;
  border: 2px solid ${({ theme }) => theme.colors.green};
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  pointer-events: none;
  margin: auto;
  &.solid {
    transform: rotate(0deg);
    opacity: 1;
  }
  &.animation {
    animation: ${rotate} 0.9s ease-in-out both;
  }
  @media (max-width: 1443px) {
    width: 400px;
    height: 400px;
  }
  @media (max-width: 1024px) {
    width: 500px;
    height: 500px;
  }
  @media (max-width: 850px) {
    width: 400px;
    height: 400px;
  }
  @media (max-width: 426px) {
    width: 280px;
    height: 280px;
  }
`

const StyledGatsbyImage = styled.div`
  height: 100%;
  position: relative;
  object-fit: cover;
  overflow: hidden;
  width: 100%;
  z-index: 1;
  transition: filter 0.5s;
  /* &:hover {
    filter: invert(1);
  } */
  .gatsby-image-wrapper {
    height: 100%;
  }
  &::after {
    content: "";
    background-image: url(${photoElement});
    background-size: cover;
    background-position: center;
    width: 300px;
    height: 191px;
    top: 0;
    position: absolute;
    z-index: 2;
    pointer-events: none;

    @media (max-width: 768px) {
      width: 150px;
      height: 95px;
    }
  }
`

const signature = keyframes`
  from {
opacity:0;
  }

  to {
    opacity:1;
  }
`

const StyledSignature = styled.img`
  width: 162px;
  height: auto;
  position: absolute;
  right: 150px;
  animation: ${signature} 0.6s 0.2s ease-in-out both;
  @media (max-width: 1443px) {
    width: 116px;
  }
  @media (max-width: 1024px) {
    right: 0px;
    width: 162px;
  }
  @media (max-width: 850px) {
    width: 100px;
  }
  @media (max-width: 426px) {
    width: 90px;
  }
`

const StyledButtonModule = styled.div`
  pointer-events: all;
  display: flex;
  position: absolute;
  z-index: 200;
  bottom: -34px;
  align-items: center;
  left: 50px;
  right: 0;
  margin: 0 auto;
  width: max-content;
  animation: ${visibility} 0.5s 0.9s ease-in-out both;
  @media (max-width: 1440px) {
    bottom: -27px;
  }
  @media (max-width: 1024px) {
    left: 0px;
  }
  @media (max-width: 550px) {
    display: none;
  }

  &.mobile {
    display: none;
    position: relative;
    @media (max-width: 550px) {
      display: flex;
    }
  }
`

const StyledButtonInfo = styled.h4`
  font-size: 18px;
  line-height: 22px;
  font-family: "Lato";
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-right: 40px;
  @media (max-width: 1440px) {
    font-size: 14px;
    line-height: 17px;
  }
  @media (max-width: 1024px) {
    margin-right: 8px;
  }
  @media (max-width: 500px) {
    /* display: none; */
  }
`

const StyledButton = styled.div`
  position: relative;
  pointer-events: all;
  z-index: 100;
  font-size: 38px;
  line-height: 49px;
  font-family: "ChineseRocks";
  letter-spacing: 0px;
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  transition: all 0.3s;
  @media (max-width: 1440px) {
    font-size: 31px;
    line-height: 37px;
  }

  @media (max-width: 500px) {
    font-size: 31px;
    margin-left: 0px;
    line-height: 37px;
    /* color: white; */
  }
  &:hover {
    &::before {
      content: "";
      position: absolute;
      height: 32px;
      width: 335px;
      top: 7px;
      right: -10px;
      background-image: url(${vividHover});
    }
    span {
      &.bigger {
        font-size: 48px;
        @media (max-width: 1200px) {
          font-size: 31px;
          line-height: 37px;
        }
      }
    }
  }
`

const pattern = keyframes`
  from {
      width:1vw;
      height:auto;
      opacity:1;
    }
  to {
    width:100vw;
    height:auto;
    opacity:0.03
  }
`

const StyledPattern = styled.img`
  width: 500px;
  height: 500px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  pointer-events: none;
  margin: auto;
  &.animation {
    animation: ${pattern} 0.5s linear both;
  }
  &.solid {
    width: 100vw;
    height: auto;
    opacity: 0.03;
  }
`

const PageName = styled.p`
  font-family: "Lato";
  font-size: 14px;
  letter-spacing: 5px;
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  transition: transform 0.3s ease-in-out;
  position: fixed;
  color: black;
  top: 51px;
  right: 100px;
  display: none;
  z-index: 11;
  @media (max-width: 1030px) {
    display: block;
  }
`

const Work = ({ data, location }) => {
  const [currentSlide, setCurrentSlide] = useState(0)

  const fromHome = location?.state?.fromHome || ""
  const menuData = data?.datoCmsMenu || ""
  const moduleData = data?.datoCmsWork || ""

  const headline = moduleData?.headline || ""
  const description = moduleData?.description || ""

  const photo = moduleData?.mainphoto?.fluid || ""
  const pageName = moduleData?.pagename || ""
  const buttontext = moduleData?.buttontext || ""
  const buttonlink = moduleData?.buttonlink || ""
  const buttoninfo = moduleData?.buttoninfo || ""
  const pattern = moduleData?.backgroundpattern?.url || ""
  const gallery = moduleData?.gallery || []
  const menuPattern = moduleData?.menupattern?.url || ""
  const globalData = data?.datoCmsGlobal || ""

  const dispatch = useDispatch()
  dispatch(setActivePage("work"))
  dispatch(setActiveLanguage("en"))
  dispatch(setActivePatternMenu(menuPattern))

  const next = () => {
    if (currentSlide >= gallery.length - 1) {
      setCurrentSlide(0)
    } else {
      setCurrentSlide(currentSlide + 1)
    }
  }

  return (
    <Layout>
      <Seo title={pageName} />
      <PageName>{pageName}</PageName>
      <StyledPattern
        className={fromHome ? "animation" : "solid"}
        src={pattern}
      />
      <Menu data={menuData} globalData={globalData} />
      <StyledBackgroundWrapper>
        <StyledRectangle className={fromHome ? "solid" : "animation"}>
          <StyledButtonModule>
            <StyledButtonInfo>{buttoninfo}</StyledButtonInfo>
            <StyledButton
              as="a"
              target="_blank"
              href={buttonlink}
              dangerouslySetInnerHTML={getHtmlFromRedactorField(buttontext)}
            />
          </StyledButtonModule>
        </StyledRectangle>

        <StyledContentWrapper>
          <StyledCopyWrapper>
            <HeadingH2 className="work">{headline}</HeadingH2>

            <Paragraph className="ml-20">{description}</Paragraph>
          </StyledCopyWrapper>
          <StyledPhotoWrapper onMouseEnter={() => next()}>
            <Carousel
              showStatus={false}
              showArrows={false}
              showIndicators={false}
              autoPlay={true}
              interval="5000"
              showThumbs={false}
              infiniteLoop={true}
              selectedItem={currentSlide}
            >
              {gallery.map((item, key) => {
                const img = item?.fluid || ""
                return (
                  <StyledGatsbyImage key={key + img}>
                    <Img fluid={img} />
                  </StyledGatsbyImage>
                )
              })}
            </Carousel>
          </StyledPhotoWrapper>

          <StyledButtonModule className="mobile">
            <StyledButtonInfo>{buttoninfo}</StyledButtonInfo>
            <StyledButton
              as="a"
              target="_blank"
              href={buttonlink}
              dangerouslySetInnerHTML={getHtmlFromRedactorField(buttontext)}
            />
          </StyledButtonModule>

          <StyledTextBottomMobile src={textBottomGreyMobile} />
        </StyledContentWrapper>

        <StyledTextBottom src={textBottomGrey} />
      </StyledBackgroundWrapper>
    </Layout>
  )
}

export default Work

export const query = graphql`
  query workQuery {
    datoCmsGlobal {
      languages
      socialmedia {
        name
        link
      }
      logo {
        url
      }
    }
    datoCmsWork {
      headline
      description
      pagename
      buttontext
      buttonlink
      buttoninfo
      menupattern {
        url
      }
      backgroundpattern {
        url
      }
      gallery {
        fluid(maxWidth: 1000) {
          ...GatsbyDatoCmsFluid
        }
      }
      mainphoto {
        fluid {
          ...GatsbyDatoCmsFluid
        }
      }
    }
    datoCmsMenu {
      _allMenuitemLocales {
        locale
      }
      menuitem {
        pagelink {
          __typename
          ... on DatoCmsContact {
            pagename
            menupattern {
              url
            }
          }
          ... on DatoCmsWork {
            pagename
            menupattern {
              url
            }
          }
          ... on DatoCmsHomepage {
            id
          }
          ... on DatoCmsAbout {
            pagename
            menupattern {
              url
            }
          }
        }
      }
    }
  }
`
